
import { Context } from '@nuxt/types'
import { mapGetters } from 'vuex'
import { decodeHTMLEntities } from '~/domain/helpers'
import MachinationsLightPage from '~/domain/pages/MachinationsLightPage'

export default MachinationsLightPage.extend({
  name: 'DocumentationPage',
  layout: 'docsLayout',
  key(route) {
    return 'doc-slug-' + route.fullPath
  },
  head() {
    // @ts-ignore
    const pageHead = this.pageHead()
    return {
      ...pageHead,
      // @ts-ignore
      title: `${decodeHTMLEntities(this.selectedPage?.title)} • ${this.$t(
        'capitalDocs'
      )}`,
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: `${decodeHTMLEntities(this.selectedPage?.title)} • ${this.$t(
            'capitalDocs'
          )}`,
        },
        ...pageHead?.meta,
      ],
    }
  },
  computed: {
    ...mapGetters({
      selectedPage: 'docs/selectedPage',
    }),
    currentPageSlug() {
      return this.$route.params.docSlug
    },
  },
  async asyncData(context: Context) {
    const { store, $config, params, error } = context
    const { wpBaseURL } = $config

    await store.dispatch('common/setupPageLight', { context })

    if (!store.state.docs.chaptersLoaded) {
      await store.dispatch('docs/loadChapters', {
        context,
      })
    }

    try {
      const docPageData = await store.dispatch('docs/selectPageBySlug', {
        context,
        slugPage: params.docSlug,
        wpBaseURL,
      })
      if (!docPageData) {
        error({
          statusCode: 404,
          message: `No documentation page found: ${params.docSlug}`,
        })
      }
    } catch (e) {
      console.error('Error', e)
    }
  },
})
